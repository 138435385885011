import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import Image from '../../uielements/Image'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import Spacer from '../../uielements/Spacer'

const SlickWrapper = styled.div`
    picture {
        width: 100%;
        display: block;

        img {
            width: 100%;
            display: block;
        }
    }

    .slick-prev {
        left: 30px;
        z-index: 9;
        width: 50px;
        height: 50px;
    }

    .slick-next {
        right: 30px;
        z-index: 9;
        width: 50px;
        height: 50px;
    }

    .slick-prev:before,
    .slick-next:before {
        font-size: 50px;
    }

    .slick-slider {
        margin: 30px auto;
    }
`

const settings = {
    dots: true,
    arrows: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    rows: 1,
    slidesPerRow: 1,
}

const MySlickSlider = ({ props, data }) => {
    const { i18n } = useTranslation()
    return data.slides.nodes.map(
        slide =>
            slide.slug === props.slug &&
            i18n.language === slide.node_locale && (
                <SlickWrapper key={slide.id}>
                    <Spacer top="3" />
                    <Slider {...settings}>
                        {slide.media.map(medium => (
                            <Image obj={medium} />
                        ))}
                    </Slider>
                </SlickWrapper>
            )
    )
}

const SlickSlider = props => {
    return (
        <StaticQuery
            query={graphql`
                query sliderQuery {
                    slides: allContentfulSlider {
                        nodes {
                            id
                            title
                            slug
                            node_locale
                            media {
                                url
                                title
                                gatsbyImageData(
                                    quality: 75
                                    jpegProgressive: true
                                    sizes: "1920, 960, 480"
                                )
                            }
                        }
                    }
                }
            `}
            render={data2 => <MySlickSlider data={data2} props={props} />}
        ></StaticQuery>
    )
}

export default SlickSlider
