import React from 'react'
import SlickSlider from '../components/buildingBlocks/SlickSlider'
import Layout from '../components/layout'
import Spacer from '../components/uielements/Spacer'
import MainTitle from '../components/uielements/MainTitle'
import { useTranslation } from 'react-i18next'
import Wrapper from '../components/Wrapper'
import { Helmet } from 'react-helmet'
import Seo from '../components/seo'

const GalleryPage = () => {
    const { t } = useTranslation()

    return (
        <Layout>
            <Helmet>
                <meta property="og:title" content={t('activities')} />
            </Helmet>
            <Wrapper>
                <Seo title={t('activities')} />
                <MainTitle>{t('activities')}</MainTitle>
                <Spacer top="3" />
                <SlickSlider slug="drastiriotites" />
                <Spacer top="4" />
            </Wrapper>
        </Layout>
    )
}

export default GalleryPage
